import React, { useState, useEffect, useRef } from 'react';
import  '../styles/Dashboard.css';
import axios from 'axios';
import  '../styles/Sidebar.css';
import { IoMdMenu } from "react-icons/io";
import { MdLightMode,MdDarkMode } from "react-icons/md";
import DogProfile from '../assets/dog.jpg';
import { eventEmitter } from '../services/EventEmitter';
import { IoNotifications } from 'react-icons/io5';
import io from 'socket.io-client';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Navbar = ({ isLightModeActive, toggleLightMode, toggleSidebar, isSidebarExpanded }) => {
    const [userData, setUserData] = useState(null);
    const [showNotifications, setShowNotifications] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [newNotificationCount, setNewNotificationCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const notificationRef = useRef(null);
    const dropdownRef = useRef(null);

    const handleSendEmailToHospitalityUsers = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/api/users/send-email-hospitality`, { withCredentials: true });
            console.log('Email and business data:', response.data);
        } catch (error) {
            console.error('Error fetching hospitality user data:', error);
        }
    };
    const handleClickOutside = (event) => {
        if (
            notificationRef.current &&
            dropdownRef.current &&
            !notificationRef.current.contains(event.target) &&
            !dropdownRef.current.contains(event.target)
        ) {
            setShowNotifications(false);
        }
    };

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/api/users/get_user_details`, { withCredentials: true });
                setUserData(response.data);
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchUserData();
        const handleProfileUpdate = () => {
            fetchUserData();
        };
        const fetchNotifications = async () => {
            try {
                setIsLoading(true);
                const response = await axios.get(`${API_BASE_URL}/api/Activity/notifications`, { withCredentials: true });
                setNotifications(response.data.notifications);
                setNewNotificationCount(response.data.newNotificationsCount);
            } catch (error) {
                console.error('Error fetching notifications:', error);
                setError(error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchNotifications();

        eventEmitter.on('profileUpdated', handleProfileUpdate);

        return () => {
            eventEmitter.events['profileUpdated'] = eventEmitter.events['profileUpdated'].filter(listener => listener !== handleProfileUpdate);
        };
    }, []);

    const handleNotificationsClick = async () => {
        setShowNotifications(!showNotifications);
        if (!showNotifications && newNotificationCount > 0) {
            try {
                await axios.post(`${API_BASE_URL}/api/Activity/notifications/mark-as-seen`, {}, { withCredentials: true });
                setNewNotificationCount(0);
            } catch (error) {
                console.error('Error marking notifications as seen:', error);
            }
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    if (isLoading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error loading data!</p>;
    }

    return (
        <div className="navbar-container">
            <div className="navbar-top">
                {!isSidebarExpanded && (
                    <button id="menu-btn" onClick={toggleSidebar} className="sidebar-toggle-btn">
                        <IoMdMenu size={25} />
                    </button>
                )}
                <div className="navbar-right">
                    <div className="notification-icon" ref={notificationRef} onClick={handleNotificationsClick}>
                        <IoNotifications size={25} className="notification-bell" />
                        {newNotificationCount > 0 && <span className="notification-count">{newNotificationCount}</span>}
                        {showNotifications && (
                            <div className="notifications-dropdown" ref={dropdownRef}>
                                {notifications.map((notification, index) => (
                                    <div key={index} className={`notification-item ${!notification.userSeen.find(seen => seen.userIdSeen === userData._id) ? 'unseen' : ''}`}>
                                        {notification.activity} - {new Date(notification.createdAt).toLocaleTimeString()}
                                        {index < notifications.length - 1 && <hr className="notification-separator" />}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    <div className="theme-toggler">
                        <span className={isLightModeActive ? "active" : ""} onClick={toggleLightMode}>
                            <MdLightMode size={20} className={isLightModeActive ? "light-icon" : "dark-icon"} />
                        </span>
                        <span className={!isLightModeActive ? "active" : ""} onClick={toggleLightMode}>
                            <MdDarkMode size={20} />
                        </span>
                    </div>
                    <div className="profile">
                        {userData && (
                            <div className="info">
                                <p>Hey, <b>{userData.firstName} {userData.lastName}</b></p>
                                <small className="text-muted">{userData.role}</small>
                            </div>
                        )}
                        <div className="profile-photo">
                            {userData && userData.profileImage && (
                                <img src={`${API_BASE_URL}/${userData.profileImage}`} alt="Profile" />
                            )}
                        </div>
                    </div>
                </div>
                {/* <button onClick={handleSendEmailToHospitalityUsers} className="email-button">
                    Send Email to Hospitality Users
                </button> */}
            </div>
        </div>
    );
};

export default Navbar;
import React, { useState, useEffect } from 'react';
import { DatePicker, Table, Button, Tabs, Select,Card, Collapse, Row, Col, Pagination } from 'antd';
import { useParams } from 'react-router-dom';
import { ReloadOutlined } from '@ant-design/icons';
import moment from 'moment';
import '../styles/UserTimesheetPage.css';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto'; // Required for Chart.js
dayjs.extend(isBetween);

const { RangePicker } = DatePicker;
const { TabPane } = Tabs;
const { Panel } = Collapse;
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const monthOptions = [
  { label: 'มกราคม', value: 1 },
  { label: 'กุมภาพันธ์', value: 2 },
  { label: 'มีนาคม', value: 3 },
  { label: 'เมษายน', value: 4 },
  { label: 'พฤษภาคม', value: 5 },
  { label: 'มิถุนายน', value: 6 },
  { label: 'กรกฎาคม', value: 7 },
  { label: 'สิงหาคม', value: 8 },
  { label: 'กันยายน', value: 9 },
  { label: 'ตุลาคม', value: 10 },
  { label: 'พฤศจิกายน', value: 11 },
  { label: 'ธันวาคม', value: 12 },
];

const yearOptions = Array.from({ length: 10 }, (_, i) => {
  const thaiYear = dayjs().year() + 543 - i; // Convert to Thai year
  return { label: thaiYear.toString(), value: dayjs().year() - i };
});

const UserTimesheetPage = () => {
  const { userId } = useParams();
  const [timesheetData, setTimesheetData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState([null, null]);
  const [userName, setUserName] = useState({ firstName: '', lastName: '' });
  const [jobStats, setJobStats] = useState([]);
  const [comparisonData, setComparisonData] = useState([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [selectedJobNumber, setSelectedJobNumber] = useState(null);
  const [participantChartData, setParticipantChartData] = useState([]);
  const [selectedWeek, setSelectedWeek] = useState(null);
  const [summaryData, setSummaryData] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(dayjs().month() + 1); // Default to current month
  const [selectedYear, setSelectedYear] = useState(dayjs().year()); // Default to current year

  useEffect(() => {
    fetchUserTimesheet(userId);
    fetchUserStatistics();
  }, [userId]);

  useEffect(() => {
    fetchSummaryData(selectedMonth, selectedYear);
  }, [selectedMonth, selectedYear]);

  const fetchUserTimesheet = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/timesheet/user-timesheet/${userId}`, {
        withCredentials: true,
      });
      const { firstName, lastName, date } = response.data;
      setUserName({ firstName, lastName });
      setTimesheetData(date);
      setFilteredData(flattenTimesheetData(date));
    } catch (error) {
      console.error("Error fetching user timesheet data:", error);
    }
  };

  const fetchUserStatistics = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/timesheet/job-stats/${userId}`, {
        withCredentials: true,
      });
      setJobStats(response.data.jobStats);
      setComparisonData(response.data.comparisonData);
    } catch (error) {
      console.error("Error fetching user statistics:", error);
    }
  };

  const fetchParticipants = async (jobNumber, week) => {
    try {
      const sanitizedJobNumber = jobNumber.trim();
      const params = {};
      if (week) {
        params.weekStart = week.startOfWeek;
        params.weekEnd = week.endOfWeek;
      }
      const response = await axios.get(
        `${API_BASE_URL}/api/timesheet/participants/${encodeURIComponent(sanitizedJobNumber)}`,
        { params, withCredentials: true }
      );
      setParticipantChartData(response.data);
    } catch (error) {
      console.error("Error fetching participants:", error);
    }
  };

  const flattenTimesheetData = (data) => {
    return data.flatMap((entry) =>
      entry.records.map((record) => ({
        date: entry.date,
        ...record,
      }))
    );
  };

  const fetchSummaryData = async (month, year) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/timesheet/summary/${userId}`, {
        params: { month, year },
        withCredentials: true,
      });
      setSummaryData(response.data);
    } catch (error) {
      console.error('Error fetching summary data:', error);
    }
  };

  const handleMonthYearChange = (month, year) => {
    setSelectedMonth(month);
    setSelectedYear(year);
  };

  const summaryColumns = [
    {
      title: 'Job Number',
      dataIndex: 'jobNumber',
      key: 'jobNumber',
    },
    {
      title: 'Total Hours',
      dataIndex: 'totalHours',
      key: 'totalHours',
    },
    {
      title: 'Participants',
      key: 'participants',
      render: (_, record) => (
        <ul>
          {record.participants.map((participant) => (
            <li key={participant._id}>{participant.name} - {participant.totalHours} hours</li>
          ))}
        </ul>
      ),
    },
  ];

  const handleDateRangeChange = (dates) => {
    if (!dates || dates.length === 0) {
      setFilteredData(flattenTimesheetData(timesheetData));
      return;
    }
    const [startDate, endDate] = dates;
    const formattedStartDate = dayjs(startDate).startOf('day');
    const formattedEndDate = dayjs(endDate).endOf('day');
    const filtered = timesheetData.filter((entry) => {
      const entryDate = dayjs(entry.date).startOf('day');
      return entryDate.isBetween(formattedStartDate, formattedEndDate, null, '[]');
    });
    setFilteredData(flattenTimesheetData(filtered));
  };

  const handleRefresh = () => {
    setSelectedDateRange([null, null]);
    setFilteredData(flattenTimesheetData(timesheetData));
  };

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (text) => moment(text).format('YYYY-MM-DD'),
    },
    {
      title: 'Day',
      key: 'day',
      render: (text, record) => moment(record.date).format('dddd'),
    },
    {
      title: 'Task',
      dataIndex: 'task',
      key: 'task',
    },
    {
      title: 'Job Number',
      dataIndex: 'jobNumber',
      key: 'jobNumber',
    },
    {
      title: 'Working Hours',
      dataIndex: 'workingHours',
      key: 'workingHours',
    },
    {
      title: 'Remark',
      dataIndex: 'remark',
      key: 'remark',
    },
  ];

  const expandedRowRender = (record) => {
    const weeklyDetails = record.weeklyDetails || [];
    const columns = [
      {
        title: 'Week',
        dataIndex: 'startOfWeek',
        key: 'startOfWeek',
        render: (date) => moment(date).format('YYYY-MM-DD'),
      },
      {
        title: 'Week Hours',
        dataIndex: 'weekHours',
        key: 'weekHours',
      },
    ];
    return (
      <Table
        columns={columns}
        dataSource={weeklyDetails}
        pagination={false}
        rowKey={(item) => `${record.jobNumber}-${item.startOfWeek}`}
        onRow={(week) => ({
          onClick: () => handleWeekClick(record.jobNumber, week),
        })}
      />
    );
  };

  const jobStatsColumns = [
    {
      title: 'Job Number',
      dataIndex: 'jobNumber',
      key: 'jobNumber',
    },
    {
      title: 'Total Hours (Weekly)',
      dataIndex: 'weeklyHours',
      key: 'weeklyHours',
    },
    {
      title: 'Total Hours (Overall)',
      dataIndex: 'totalHours',
      key: 'totalHours',
    },
  ];

  const chartData = {
    labels: participantChartData.map((data) => data.name) || ['No data available'],
    datasets: [
      {
        label: selectedWeek ? 'Weekly Hours' : 'Total Hours',
        data: participantChartData.map((data) =>
          selectedWeek ? data.weeklyHours : data.totalHours
        ) || [0],
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
      },
    ],
  };

  const handleRowClick = (record) => {
    const key = record.jobNumber;
    const isExpanded = expandedRowKeys.includes(key);
  
    // Always reset selectedWeek to null when switching to a new job number
    setSelectedWeek(null);
  
    if (!isExpanded) {
      setSelectedJobNumber(key);
      fetchParticipants(key); // Fetch total participants data for the selected job
    }
  
    setExpandedRowKeys((prevKeys) =>
      isExpanded ? prevKeys.filter((k) => k !== key) : [key]
    );
  };

  const handleWeekClick = (jobNumber, week) => {
    const weekData = {
      startOfWeek: moment(week.startOfWeek).startOf('day').toISOString(),
      endOfWeek: moment(week.startOfWeek).add(6, 'days').endOf('day').toISOString(),
    };
  
    // Toggle between showing weekly hours and total hours
    if (
      selectedWeek &&
      selectedWeek.startOfWeek === weekData.startOfWeek &&
      selectedWeek.endOfWeek === weekData.endOfWeek
    ) {
      // If clicking the same week again, reset to show total hours
      setSelectedWeek(null);
      fetchParticipants(jobNumber); // Fetch total participants for the job
    } else {
      // Set the selected week and fetch weekly participants data
      setSelectedWeek(weekData);
      fetchParticipants(jobNumber, weekData);
    }
  };

  return (
    <div className="user-timesheet-page">
      <h2>Timesheet for {userName.firstName} {userName.lastName}</h2>

      <Tabs defaultActiveKey="1">
        <TabPane tab="Timesheet" key="1">
          <div className="date-filter">
            <RangePicker
              value={selectedDateRange}
              onChange={handleDateRangeChange}
              allowClear
            />
            <Button
              onClick={handleRefresh}
              type="primary"
              icon={<ReloadOutlined />}
              shape="circle"
              style={{ marginLeft: "10px" }}
            />
          </div>
          <Table
            columns={columns}
            dataSource={filteredData}
            pagination={false}
            rowKey="date"
          />
        </TabPane>
        <TabPane tab="Statistics" key="2">
          <h3>Job Participation and Hours</h3>
          <Table
            columns={jobStatsColumns}
            dataSource={jobStats}
            expandedRowRender={expandedRowRender}
            rowKey="jobNumber"
            onRow={(record) => ({
              onClick: () => handleRowClick(record),
            })}
            expandedRowKeys={expandedRowKeys}
            pagination={{
              pageSize: 5, // Show 5 rows per page
              showSizeChanger: false, // Hide page size options
            }}
          />
          <h3>Comparison Chart</h3>
          <Bar data={chartData} />
        </TabPane>
        <TabPane tab="Summary" key="3">
          <div style={{ marginBottom: '20px', display: 'flex', gap: '10px' }}>
            <Select
              style={{ width: 150 }}
              options={monthOptions}
              value={selectedMonth}
              onChange={(value) => handleMonthYearChange(value, selectedYear)}
              placeholder="Select Month"
            />
            <Select
              style={{ width: 150 }}
              options={yearOptions}
              value={selectedYear}
              onChange={(value) => handleMonthYearChange(selectedMonth, value)}
              placeholder="Select Year"
            />
          </div>

          {summaryData ? (
            <div style={{ marginTop: '20px' }}>
              {/* Monthly Summary */}
              <Card
                title="Monthly Summary"
                bordered={true}
                style={{
                  marginBottom: '20px',
                  borderRadius: '8px',
                }}
              >
                <p>
                  <strong>Month:</strong> {monthOptions.find((m) => m.value === selectedMonth)?.label}
                  <br />
                  <strong>Year:</strong> {selectedYear + 543} (Thai Year)
                  <br />
                  <strong>Total Hours:</strong> {summaryData.totalHours} hrs
                </p>
              </Card>

              {/* Total Hours by Job */}
              <Card
                title="Total Hours by Job"
                bordered={true}
                style={{
                  marginBottom: '20px',
                  borderRadius: '8px',
                }}
              >
                {summaryData.jobSummary && Object.entries(summaryData.jobSummary).length > 0 ? (
                  <Collapse>
                    {Object.entries(summaryData.jobSummary).map(([jobNumber, data]) => (
                      <Panel
                        header={`Job Number: ${jobNumber} | Total Hours: ${data.totalHours} hrs`}
                        key={jobNumber}
                      >
                        <p><strong>Participants:</strong></p>
                        <ul>
                          {data.participants.length > 0 ? (
                            data.participants.map((participant) => (
                              <li key={participant._id}>
                                {participant.name} - {participant.totalHours} hrs
                              </li>
                            ))
                          ) : (
                            <li>No participants</li>
                          )}
                        </ul>
                      </Panel>
                    ))}
                  </Collapse>
                ) : (
                  <p>No job data available.</p>
                )}
              </Card>

              {/* Weekly Breakdown */}
              <Card
                title="Weekly Breakdown"
                bordered={true}
                style={{
                  marginBottom: '20px',
                  borderRadius: '8px',
                }}
              >
                {summaryData.weeklySummary && summaryData.weeklySummary.length > 0 ? (
                  <Collapse>
                    {summaryData.weeklySummary.map((week, index) => (
                      <Panel
                        header={`Week: ${moment(week.start).format('YYYY-MM-DD')} to ${moment(week.end).format('YYYY-MM-DD')} | Total Hours: ${week.totalHours} hrs`}
                        key={index}
                      >
                        <p><strong>Jobs Participated:</strong></p>
                        <ul>
                          {week.jobs.map((job) => (
                            <li key={job.jobNumber}>
                              {job.jobNumber} - {job.hours} hrs
                            </li>
                          ))}
                        </ul>
                      </Panel>
                    ))}
                  </Collapse>
                ) : (
                  <p>No weekly data available.</p>
                )}
              </Card>
            </div>
          ) : (
            <p>Loading summary data...</p>
          )}
        </TabPane>

      </Tabs>
    </div>
  );
};

export default UserTimesheetPage;
